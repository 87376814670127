<template>
  <div class="px-3 py-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/logo/logo.png" alt="logo" width="80" />
        <span class="title-logo"> Smartworks </span>
      </div>

      <b-link
        :to="{ name: 'performance/goal' }"
        class="d-flex align-items-center bg-white p-1 rounded-pill cursor-pointer"
      >
        <feather-icon icon="ArrowLeftIcon" />
        <span class="bold ml-1">Back</span>
      </b-link>
    </div>

    <div class="text-center mt-3">
      <h2 style="color: #1e1e1e">Overall Evaluation</h2>
      <span class="text-muted"> This Employee Overall Evaluation </span>
    </div>

    <b-row class="justify-content-center">
      <b-col md="6">
        <b-card class="mt-3 rounded-3">
          <form @submit.prevent>
            <b-form-group label="Overall Score">
              <b-form-input
                id="behavior"
                v-model="overAllScoreText"
                size="sm"
                readonly
              />
            </b-form-group>
            <b-form-group label="Overall Comment">
              <b-form-textarea
                id="overall-comment"
                v-model="overAllComment"
                size="sm"
              >
              </b-form-textarea>
            </b-form-group>
          </form>

          <div class="text-right mt-2">
            <b-link :to="{ name: 'performance/goal' }">
              <b-button
                variant="outline-primary"
                class="rounded-3 mr-1"
                size="sm"
              >
                Back to the form
              </b-button>
            </b-link>
            <b-button
              type="submit"
              variant="primary"
              class="rounded-3"
              size="sm"
              @click="goals_summary($event)"
            >
              Continue
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.title-logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #423ffd;
  margin-left: 0.5rem;
}

.bg-white {
  background-color: #fff;
}

.rounded-3 {
  border-radius: 1.75rem;
}
</style>

<script>
export default {
  data() {
    return {
      overAllScore: "",
      overAllScoreText: "",
      overAllComment: "",
    };
  },
  mounted() {
    let goalsArray = JSON.parse(localStorage.getItem("goals"));
    let goalsItemsArray = JSON.parse(localStorage.getItem("goals"));
    let goalsFinalScore = [];
    let score = 0;
    let behavior = [];
    goalsArray[0].other.map((items) => {
      goalsItemsArray.push(items);
    });

    goalsItemsArray[0].other.map((item) => {
      if (localStorage.getItem("rtype") == "final") {
        goalsFinalScore.push(item.final_manager_score);
        behavior.push(item.final_manager_behavior);
      } else {
        goalsFinalScore.push(item.final_score);
        behavior.push(item.behavior);
      }
    });

    if (
      goalsFinalScore.filter((score) => score == 0).length >
      goalsFinalScore.filter((score) => score == 1).length
    ) {
      score = 0;
    } else if (
      goalsFinalScore.filter((score) => score == 0).length >
      goalsFinalScore.filter((score) => score == 2).length
    ) {
      score = 0;
    } else if (
      goalsFinalScore.filter((score) => score == 0).length >
      goalsFinalScore.filter((score) => score == 3).length
    ) {
      score = 0;
    } else if (
      goalsFinalScore.filter((score) => score == 0).length >
      goalsFinalScore.filter((score) => score == 4).length
    ) {
      score = 0;
    }

    if (
      goalsFinalScore.filter((score) => score == 1).length >
      goalsFinalScore.filter((score) => score == 0).length
    ) {
      score = 1;
    } else if (
      goalsFinalScore.filter((score) => score == 1).length >
      goalsFinalScore.filter((score) => score == 1).length
    ) {
      score = 1;
    } else if (
      goalsFinalScore.filter((score) => score == 1).length >
      goalsFinalScore.filter((score) => score == 2).length
    ) {
      score = 1;
    } else if (
      goalsFinalScore.filter((score) => score == 1).length >
      goalsFinalScore.filter((score) => score == 3).length
    ) {
      score = 1;
    } else if (
      goalsFinalScore.filter((score) => score == 1).length >
      goalsFinalScore.filter((score) => score == 4).length
    ) {
      score = 1;
    }

    if (
      goalsFinalScore.filter((score) => score == 2).length >
      goalsFinalScore.filter((score) => score == 0).length
    ) {
      score = 2;
    } else if (
      goalsFinalScore.filter((score) => score == 2).length >
      goalsFinalScore.filter((score) => score == 1).length
    ) {
      score = 2;
    } else if (
      goalsFinalScore.filter((score) => score == 2).length >
      goalsFinalScore.filter((score) => score == 2).length
    ) {
      score = 2;
    } else if (
      goalsFinalScore.filter((score) => score == 2).length >
      goalsFinalScore.filter((score) => score == 3).length
    ) {
      score = 2;
    } else if (
      goalsFinalScore.filter((score) => score == 2).length >
      goalsFinalScore.filter((score) => score == 4).length
    ) {
      score = 2;
    }

    if (
      goalsFinalScore.filter((score) => score == 3).length >
      goalsFinalScore.filter((score) => score == 0).length
    ) {
      score = 3;
    } else if (
      goalsFinalScore.filter((score) => score == 3).length >
      goalsFinalScore.filter((score) => score == 1).length
    ) {
      score = 3;
    } else if (
      goalsFinalScore.filter((score) => score == 3).length >
      goalsFinalScore.filter((score) => score == 2).length
    ) {
      score = 3;
    } else if (
      goalsFinalScore.filter((score) => score == 3).length >
      goalsFinalScore.filter((score) => score == 3).length
    ) {
      score = 3;
    } else if (
      goalsFinalScore.filter((score) => score == 3).length >
      goalsFinalScore.filter((score) => score == 4).length
    ) {
      score = 3;
    }

    if (
      goalsFinalScore.filter((score) => score == 4).length >
      goalsFinalScore.filter((score) => score == 0).length
    ) {
      score = 4;
    } else if (
      goalsFinalScore.filter((score) => score == 4).length >
      goalsFinalScore.filter((score) => score == 4).length
    ) {
      score = 4;
    } else if (
      goalsFinalScore.filter((score) => score == 4).length >
      goalsFinalScore.filter((score) => score == 2).length
    ) {
      score = 4;
    } else if (
      goalsFinalScore.filter((score) => score == 4).length >
      goalsFinalScore.filter((score) => score == 3).length
    ) {
      score = 4;
    } else if (
      goalsFinalScore.filter((score) => score == 4).length >
      goalsFinalScore.filter((score) => score == 4).length
    ) {
      score = 4;
    }

    if (score == 0 && behavior[0] == 1) {
      this.overAllScore = 0;
      this.overAllScoreText = "Has not met Objectives";
    } else if (score == 0 && behavior[0] == 2) {
      this.overAllScore = 0;
      this.overAllScoreText = "Has not met Objectives";
    } else if (score == 0 && behavior[0] == 3) {
      this.overAllScore = 1;
      this.overAllScoreText = "Met some Objectives but not all";
    } else if (score == 1 && behavior[0] == 1) {
      this.overAllScore = 1;
      this.overAllScoreText = "Met some Objectives but not all";
    } else if (score == 1 && behavior[0] == 2) {
      this.overAllScore = 1;
      this.overAllScoreText = "Met some Objectives but not all";
    } else if (score == 1 && behavior[0] == 3) {
      this.overAllScore = 1;
      this.overAllScoreText = "Met some Objectives but not all";
    } else if (score == 2 && behavior[0] == 1) {
      this.overAllScore = 1;
      this.overAllScoreText = "Met some Objectives but not all";
    } else if (score == 2 && behavior[0] == 2) {
      this.overAllScore = 2;
      this.overAllScoreText = "Met Objectives";
    } else if (score == 2 && behavior[0] == 3) {
      this.overAllScore = 3;
      this.overAllScoreText = "Exceeded some Objectives & met others";
    } else if (score == 3 && behavior[0] == 1) {
      this.overAllScore = 2;
      this.overAllScoreText = "Met Objectives";
    } else if (score == 3 && behavior[0] == 2) {
      this.overAllScore = 3;
      this.overAllScoreText = "Exceeded some Objectives & met others";
    } else if (score == 3 && behavior[0] == 3) {
      this.overAllScore = 3;
      this.overAllScoreText = "Exceeded some Objectives & met others";
    } else if (score == 4 && behavior[0] == 1) {
      this.overAllScore = 3;
      this.overAllScoreText = "Exceeded some Objectives & met others";
    } else if (score == 4 && behavior[0] == 2) {
      this.overAllScore = 4;
      this.overAllScoreText = "Exceeded all Objectives";
    } else if (score == 4 && behavior[0] == 3) {
      this.overAllScore = 4;
      this.overAllScoreText = "Exceeded all Objectives";
    }
  },
  methods: {
    goals_summary(e) {
      e.preventDefault();

      let goals = JSON.parse(localStorage.getItem("goals"));

      goals.forEach((item) => {
        if (item.other && Array.isArray(item.other)) {
          item.other.forEach((otherItem) => {
            if (
              otherItem.overAllScore &&
              Array.isArray(otherItem.overAllScore)
            ) {
              otherItem.overAllScore.push(this.overAllScore);
              otherItem.overAllComment.push(this.overAllComment);
            } else {
              otherItem.overAllScore = this.overAllScore;
              otherItem.overAllComment = this.overAllComment;
            }
          });
        } else {
          item.overAllScore = [{ overAllScore: this.overAllScore }];
          item.overAllComment = [{ overAllComment: this.overAllComment }];
        }
      });

      localStorage.setItem("goals", JSON.stringify(goals));

      this.$router.push("/performance/goal/summary");
    },
  },
};
</script>